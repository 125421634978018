import React from "react";
import { ContentContainer } from "../components/ContentContainer";
import Layout from "../components/Layout";

export default function AboutMe(props: {}) {
  return (
    <Layout>
      <ContentContainer size="narrow">
        <h1 className="text-5xl uppercase font-thin text-center antialiased mb-6">
          Nathalie Johansson
        </h1>

        <p className="my-4">
          Jag är en ”Amazing organizing woman” med ett öga för färg och form,
          ett huvud för det tekniska och ett hjärta för fotografi. Som ser livet
          från den positiva sidan och alltid har ett leende på läpparna!
        </p>

        <p className="my-4">
          <strong>Ett öga för färg och form.</strong>
          Förkärleken till färg och form har alltid funnits där och jag har hela
          livet tyckt om att skapa saker. I min åsikt är inget jobb är för stort
          eller för litet för att ha dålig design. Oavsett vem jag skapar
          material för så vill jag vara stolt över det jag lämnar ifrån mig.
        </p>

        <p className="my-4">
          <strong>Ett huvud för det tekniska.</strong>
          Utöver ett öga för färg och form så har jag även ett bra öga för
          detaljer. Mina hökögon är ofta uppskattade av både kunder och
          kollegor. Jag har stor förståelse för hur hela processen - från idé
          till färdig produkt - fungerar i praktiken. Jag har lång erfarenhet av
          att skapa tryckoriginal med hög kvalitet.
        </p>

        <p className="my-4">
          Jag är också van vid ett högt arbetstempo och bra på att strukturera
          och planera. Det är därför som mina vänner kommit fram till att om jag
          vore en superhjälte så skulle jag vara ”Fix-it girl” eller ”Amazing
          organizing woman”!
        </p>

        <p className="my-4">
          <strong>Ett hjärta för fotografi.</strong>
          Jag älskar fotografi och att stå bakom kameran och föreviga ögonblick
          är något som ligger mig varmt om hjärtat. Jag blir taggad av att hitta
          nya vinklar och perspektiv på tillvaron. Fotografi är något jag gärna
          kombinerar med mitt stora reseintresse. Jag ser alltid fram emot att
          uppleva nya platser runt om i världen. Annars tar jag gärna en öl med
          mina vänner till tonerna av klassisk rockmusik.
        </p>

        <p className="my-4">
          Tveka inte att kontakta mig om du vill veta mer om mig och det jag
          gör!
        </p>
      </ContentContainer>
    </Layout>
  );
}
